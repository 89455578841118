/* Theme Name:Evaxo - Responsive Multipurpose Blog Page Template
Author: Zoyothemes
Version: 1.0.0
Created: February 2018
File Description:Main JS file of the template
*/
/*=========================
*  1.Helper          *
*  2.Title           *
*  3.Home            *
*  4.Portfolio       * 
*  5.About           *
*  6.Testimonial     *
*  7.team            * 
*  8.Services        *
*  9.Client          * 
*  10.Blog           * 
*  11.Contact        *
*  12.Footer         *
*  13.Respomsive     *
===========================*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 100;
  src: url('/assets/fonts/RobotoMono-100-italic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 200;
  src: url('/assets/fonts/RobotoMono-200-italic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 300;
  src: url('/assets/fonts/RobotoMono-300-italic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/RobotoMono-400-italic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 500;
  src: url('/assets/fonts/RobotoMono-500-italic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 600;
  src: url('/assets/fonts/RobotoMono-600-italic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/RobotoMono-700-italic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 100;
  src: url('/assets/fonts/RobotoMono-100.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 200;
  src: url('/assets/fonts/RobotoMono-200.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/RobotoMono-300.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/RobotoMono-400.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/RobotoMono-500.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/RobotoMono-600.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/RobotoMono-700.woff') format('woff');
}
html {
  margin-top: 60px;
  font-size: 16px;
}
@media screen and (min-width: 320px) {
  html {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  html {
    font-size: 16px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto Mono', monospace;
}
::selection {
  background: rgba(255, 88, 88, 0.7);
  color: #ffffff;
}
::-moz-selection {
  background: rgba(255, 88, 88, 0.7);
  color: #ffffff;
}
a {
  text-decoration: none !important;
}
p {
  font-size: 15px;
}
/*
img {
  max-width: 100% !important;
}
*/
/*****************
1. HELPER
*****************/
.section {
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
}
.btn {
  padding: 15px 35px;
  outline: none;
  text-decoration: none;
}
.btn-round {
  border-radius: 35px;
}
.btn-outline-white {
  background-color: transparent;
  border: 2px solid #7F7F7F;
  color: #fff;
  font-size: 12px;
  border-radius: 35px;
  letter-spacing: 2px;
  transition: all 0.3s;
  font-weight: 600;
  transition: all 0.5s;
}
.btn-outline-white:hover,
.btn-outline-white:focus,
.btn-outline-white:active,
.btn-outline-white.active,
.btn-outline-white.focus,
.btn-outline-white:active,
.btn-outline-white:focus,
.btn-outline-white:hover,
.open > .dropdown-toggle.btn-outline-white {
  color: #000;
  background-color: #fff !important;
  box-shadow: 0 -3px rgba(0, 0, 0, 0.1) inset;
}
.btn-custom {
  color: #fff;
  font-size: 14px;
  background: #ff5858;
  border: 0px;
  letter-spacing: 1px;
  transition: all 0.5s;
  font-weight: 500;
  text-transform: uppercase;
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.btn-custom.focus,
.btn-custom:active,
.btn-custom:focus,
.btn-custom:hover,
.open > .dropdown-toggle.btn-custom {
  box-shadow: 0 20px 38px rgba(0, 0, 0, 0.16);
  color: #fff;
  text-decoration: none;
}
.page-next a,
.page-next span {
  font-size: 15px;
  display: inline-block;
  font-weight: 500;
  padding-right: 8px;
  text-transform: capitalize;
  color: rgba(145, 145, 145, 0.9);
}
.page-next-level h4 {
  font-size: 28px;
  color: rgba(94, 91, 91, 0.9);
}
.bg-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
}
.vertical-content {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: row;
  -webkit-flex-direction: row;
}
/*****************
2. TITLE
******************/
.section-title h2 {
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  color: #222;
  padding-bottom: 25px;
}
/********************
3. Home
********************/
.home-height {
  border-bottom: 1px solid #f3f3f3;
  border-top: 1px solid #f2f2f2;
  padding: 175px 0;
}
.home-center {
  display: table;
  width: 100%;
  height: 100%;
}
.home-desc-center {
  display: table-cell;
  vertical-align: middle;
}
.home-title h2 {
  font-size: 28px;
  line-height: 42px;
  font-weight: unset;
  max-width: 730px;
  margin: 0 auto;
}
.home-title span {
  background: #ff5858;
  font-weight: 500;
  color: #fff;
  padding: 2px 12px;
  border-radius: 3px;
}
/********************
4. Portfolio
********************/
.container-filter {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 30px;
  padding: 0;
  text-align: center;
}
.container-filter li {
  list-style: none;
  display: inline-block;
}
.container-filter li a {
  font-size: 14px;
  padding: 7px 15px;
  color: #000 !important;
  border: 1px solid transparent;
  margin: 0px 3px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  -webkit-transition: all 0.6s;
}
.portfolioFilter {
  margin-bottom: 30px;
}
.portfolioFilter a {
  display: inline-block;
  margin: 3px 2px;
  font-size: 12px;
  text-transform: uppercase;
  padding: 7px 12px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.4s ease-in-out;
  color: #333;
}
.portfolioFilter a:hover,
.portfolioFilter a.current {
  background-color: #ff5858;
  color: #fff;
  border-radius: 3px;
}
.portfolio-box {
  overflow: hidden;
  position: relative;
  padding: 0;
  margin-top: 15px;
  margin-bottom: 15px;
}
.portfolio-box .portfolio-box-img {
  position: relative;
  overflow: hidden;
}
.portfolio-box .portfolio-box-img:after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0px;
  transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
}
.portfolio-box .portfolio-box-img > img {
  transition: all 0.3s cubic-bezier(0.3, 0.1, 0.58, 1);
  border-radius: 0;
}
.portfolio-box .portfolio-box-detail {
  opacity: 0;
  color: #ffffff;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  overflow: hidden;
  transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
}
.portfolio-box .portfolio-box-detail h4 {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 16px;
}
.portfolio-box .portfolio-box-detail p {
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
  font-weight: 500;
}
.portfolio-box:hover .portfolio-box-detail {
  transform: translateY(-100%);
  z-index: 3;
  opacity: 1;
}
.portfolio-box:hover .portfolio-box-img:after {
  background: rgba(40, 40, 46, 0.8);
}
.portfolio-box:hover .portfolio-box-img > img {
  transform: scale(1.05);
}
.gallary-title {
  padding: 25px 0;
  background: #ffffff;
}
.gallary-title h3 {
  color: #555;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.4s ease-in-out 0s;
}
.gallary-title span {
  color: #3f3f3f;
  font-size: 14px;
  text-transform: capitalize;
}
.gallary-title a {
  color: #393434;
}
.project-desc {
  line-height: 26px;
  font-size: 15px;
}
/*******************
5. About us
********************/
.page-title h2 {
  font-size: 28px;
}
.page-title p {
  line-height: 26px;
  padding-bottom: 25px;
  margin: 0;
}
.about-box span {
  font-size: 20px;
  opacity: 0.3;
}
.about-box h5 {
  font-size: 20px;
}
/*******************
6. Testimonial
********************/
.testi-client-name {
  color: #000;
  font-size: 16px;
}
.user-review {
  font-size: 18px;
  line-height: 30px;
}
.testi-box .testi-images img {
  width: 120px;
  height: auto;
  border-radius: 50%;
}
#testi .item {
  padding: 15px;
}
#testi .item img {
  display: block;
  width: 100%;
  height: auto;
}
.owl-theme .owl-controls .owl-page span {
  display: block;
  width: 10px;
  border-radius: 50%;
  height: 10px;
  margin: 0px 2px;
  filter: alpha(opacity=50);
  background: #ff5858;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}
/**********************
7.  team
***********************/
.team-box {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  overflow: hidden;
}
.team-box-img > img {
  width: 100%;
}
.team-overlay {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-color: rgba(0, 0, 0, 0.9);
  transform: translateX(-100px);
}
.team-box:hover .team-overlay {
  opacity: 0.9;
  transform: translateY(0px);
}
.team-content {
  color: #fff;
  position: relative;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}
.team-icon a {
  height: 40px;
  width: 40px;
  display: inline-block;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
}
.team-name a {
  color: #fff;
  font-size: 18px;
}
.team-info {
  padding: 15px;
}
.team-info h3 {
  font-size: 18px;
}
/*******************
8. Services
********************/
.service-box {
  padding: 20px 10px;
}
.service-icon {
  border: 2px solid #a2a2a2;
  font-size: 24px;
  height: 65px;
  font-weight: 700;
  width: 65px;
  line-height: 65px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  transition: background 0.2s, color 0.2s;
}
.service-icon:hover {
  background: #111111;
  color: #fff;
  border-color: #111;
}
.service-box h4 {
  font-size: 18px;
}
.service-box p {
  font-size: 16px;
}
/*******************
9. Client 
*******************/
.client-images img {
  max-height: 90px;
  width: auto !important;
  margin: 10px auto;
  opacity: 0.3;
  transition: all 0.5s;
}
.client-images img:hover {
  opacity: 0.6;
}
/*******************
10. Blog
********************/
.post {
  margin: 0 0 45px;
}
.post + .post {
  border-top: 1px solid rgba(238, 238, 238, 0.7);
  padding-top: 50px;
}
.post-preview {
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  margin: 0 0 25px;
}
.post-preview:hover {
  opacity: 0.7;
}
.post-header {
  margin: 0 0 30px;
}
.post-title {
  font-size: 20px;
  margin: 0 0 10px;
}
.post-title a {
  color: #333;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.post-title a:hover {
  color: #ff5858;
}
.post-meta {
  list-style: none;
  padding: 0;
  margin: 0;
  opacity: 0.7;
}
.post-meta > li {
  display: inline-block;
  margin-right: 30px;
}
.post-meta > li:last-child {
  margin-right: 0;
}
.post .post-meta a,
.post-meta a {
  color: #888;
}
.post-meta a:hover,
.post-meta a:focus,
.post-more a:hover {
  color: #ff5858;
}
.post-more a {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  text-decoration: underline !important;
}
.post-tags {
  margin: 40px 0 0;
}
.post-tags a {
  background-color: #f5f5f5;
  border-radius: 2px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 7px;
  color: #303133;
  padding: 7px 8px 5px 10px;
  margin: 5px 10px 5px 0;
}
.post-tags a:hover,
.post-tags a:focus {
  background: #0db3e3;
  color: #fff;
}
.post-content h1,
.post-content h2,
.post-content h3,
.post-content h4,
.post-content h5,
.post-content h6 {
  margin: 30px 0 20px;
}
.post-content h1:first-child,
.post-content h2:first-child,
.post-content h3:first-child,
.post-content h4:first-child,
.post-content h5:first-child,
.post-content h6:first-child {
  margin-top: 0;
}
.post-content p,
.post-content ul,
.post-content ol {
  margin: 0 0 20px;
  line-height: 25px;
}
.post-content ul,
.post-content ol {
  margin-left: 40px;
}
.post-content ul {
  list-style-type: disc;
}
.post-content ol {
  list-style-type: decimal;
}
.sidebar {
  padding-left: 30px;
}
.sidebar .widget:last-child {
  border: 0;
  padding: 0;
}
.widget_search form {
  position: relative;
}
.search-button {
  position: absolute;
  top: 0;
  height: 100%;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.widget_search .form-control:focus {
  border-color: #ddd;
  outline: 0;
  box-shadow: none;
}
.search-button:focus,
.search-button.focus,
.search-button.active.focus,
.search-button.active:focus,
.search-button:active.focus,
.search-button:active:focus {
  outline: none;
  box-shadow: none;
}
.search-button:hover {
  color: #0db3e3;
}
.widget-title {
  position: relative;
  font-size: 16px;
  margin: 0 0 25px;
  font-weight: 500;
}
.widget-title:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid #eee;
}
.widget-title:after {
  content: "";
  display: block;
  width: 35px;
  z-index: 1;
  position: relative;
  border-bottom: 1px solid #ff5858;
  margin: 20px auto 25px 0px;
}
.widget {
  margin: 0 0 40px;
  font-size: 14px;
}
.widget ul,
.widget ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
.widget ul li,
.widget ol li {
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.widget ul li:last-child,
.widget ol li:last-child {
  border: 0;
}
.widget ul li a,
.widget ol li a {
  color: #333333;
}
.widget ul li a:hover,
.widget ol li a:hover {
  color: #ff5858;
}
.widget select {
  width: 100%;
  box-shadow: none;
  border-radius: 2px;
  border-color: #eee;
  padding: 12px 15px;
  height: 49px;
  font-size: 14px;
}
.widget select:focus {
  border-color: #0db3e3;
  box-shadow: none;
}
.widget_archive ul li a,
.widget_categories ul li a {
  margin-right: 5px;
}
.post-date {
  display: block;
  opacity: 0.7;
}
.tagcloud > a {
  background: #f5f5f5;
  border-radius: 2px;
  color: #303133;
  display: inline-block;
  font-size: 9px;
  letter-spacing: 1px;
  margin: 5px 10px 5px 0;
  padding: 7px 8px 5px 10px;
  text-transform: uppercase;
}
.tagcloud > a:hover {
  background: #ff5858;
  color: #fff;
}
.widget_recent_entries_custom ul li {
  border-bottom: 1px solid rgba(238, 238, 238, 0.7);
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.widget_recent_entries_custom .wi {
  float: left;
  width: 64px;
}
.widget_recent_entries_custom .wb {
  padding-top: 2px;
  margin-left: 84px;
}
.widget_recent_entries_custom .wb a {
  color: #303133;
}
.text-widget-des {
  line-height: 24px;
}
/**********************
Blog detals
**********************/
.post blockquote {
  background-color: #f6f6f6;
  color: #5a5a5a;
  font-size: 16px;
  line-height: 26px;
  padding: 20px 50px 20px;
  border-radius: 5px;
}
.page-title h3 {
  line-height: 50px;
  margin-top: 30px;
  font-weight: 300;
  letter-spacing: 1px;
}
.media img {
  width: 52px;
  height: 52px;
}
.media-heading {
  font-size: 16px;
}
.media-body h6 {
  font-size: 13px;
}
.media-body p {
  line-height: 24px;
}
/*------------------
Pagination
--------------------*/
.pagination > li {
  display: inline-block;
  margin-right: 2px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-radius: 3px;
}
.pagination > li > a,
.pagination > li > span {
  border: 2px solid #eeeeee;
  border-radius: 3px;
  color: #777777;
  display: block;
  float: none;
  padding: 8px 18px;
}
.pagination > .active > a,
.pagination > .active > span {
  background: #27cbc0 none repeat scroll 0 0;
  border-color: #27cbc0;
  color: #ffffff;
}
.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
  background: #666 none repeat scroll 0 0;
  border-color: #666;
  color: #ffffff;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #666;
  border-color: #666;
  color: #fff;
  cursor: default;
  z-index: 3;
}
/*------------------
Blog Details
------------------*/
.project-info span {
  font-size: 14px;
  color: #444;
  font-weight: bold;
  margin-right: 5px;
}
.project-info ul li {
  font-size: 14px;
}
/********************
11.  Contact
*********************/
.contact-info {
  margin-bottom: 22px;
}
.contact-info i {
  font-size: 32px;
  opacity: 0.7;
  float: left;
  margin-right: 15px;
}
.contact-desc h5 {
  font-size: 18px;
}
.contact-desc {
  overflow: hidden;
}
.custom-form label {
  font-size: 14px;
  font-weight: 500;
}
.custom-form .form-control {
  margin-bottom: 25px;
  box-shadow: none !important;
  height: 44px;
}
.custom-form .form-control:focus {
  border-color: #ff5858;
  box-shadow: none;
  outline: none;
}
.custom-form textarea.form-control {
  height: auto;
}
/********************
12. Footer
********************/
.footer-alt {
  border-top: 1px solid #ddd;
  padding: 10px 0;
}
.footer-icons li > a {
  color: #000;
  border: 1px solid #ddd;
  width: 35px;
  font-size: 16px;
  height: 35px;
  display: block;
  line-height: 35px;
  text-align: center;
  background: #fff;
  transition: all 0.3s;
}
.footer-icons a:hover {
  color: #fff;
  background: #000;
}
.copy-rights {
  line-height: 36px;
  font-size: 15px;
  color: #797676;
}
.social-icon > li a {
  background-color: #ededed;
  border-radius: 35px;
  color: #4c5667;
  display: inline-block;
  height: 35px;
  line-height: 36px;
  text-align: center;
  width: 35px;
  margin-left: 12px;
  transition: all 0.5s;
}
.social-icon > li a:hover {
  background: #000;
  color: #fff;
}
.footer .footer-logo a {
  color: #000;
}
/*********************
13. Respomsive
**********************/
@media (min-width: 200px) and (max-width: 767px) {
  .home-title {
    font-size: 26px;
    line-height: 40px;
  }
  .section {
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
  }
  .home-sub-title {
    font-size: 16px;
    line-height: 26px;
  }
  .bg-home {
    height: auto;
  }
  .pull-none {
    text-align: center !important;
    float: none !important;
  }
  .vertical-content {
    display: inherit;
  }
}
.grid-sizer {
  width: 25%;
}
.grid-item {
  margin: 0;
  float: left;
  padding: 10px;
  background: transparent;
  position: relative;
  width: 25%;
  overflow: hidden;
  aspect-ratio: 1 / 1;
}
.grid-item div.outer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #888;
  color: #262524;
  overflow: hidden;
}
.grid-item div.outer:hover img.bg {
  transform: scale(1.1);
}
.grid-item div.outer img {
  height: 100%;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.grid-item div.outer .title {
  color: #fff;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  font-weight: bold;
  font-size: calc(1rem + 1vw);
  /* font-size: clamp(1rem, -0.875rem + 4.333vw, 3.5rem); */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  padding-bottom: 10px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .grid-item div.outer .title {
    font-size: 1.2rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .grid-item div.outer .title {
    font-size: 0.9rem;
  }
}
@media (max-width: 767px) {
  .grid-item div.outer .title {
    font-size: calc(1rem + 1vw);
  }
}
.grid-item--width2 {
  width: 50%;
}
.grid-item--width3 {
  width: 75%;
}
.grid-item--width4 {
  width: 100%;
}
@media (max-width: 767px) {
  .grid-item {
    width: 50%;
  }
  .grid-item--width2 {
    width: 100%;
  }
  .grid-item--width3 {
    width: 100%;
  }
  .grid-item--width4 {
    width: 100%;
  }
}
.scale-inner-img {
  overflow: hidden;
  display: inline-block;
}
.scale-inner-img:hover img {
  transform: scale(1.1);
}
.scale-inner-img img {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
th.min,
td.min {
  width: 1%;
  white-space: nowrap;
}
.guestbook .kommentar {
  white-space: pre-wrap;
}
.guestbook .autor {
  font-weight: bold;
}
.event-description {
  position: absolute;
  bottom: 80px;
  left: 90px;
  color: #fff;
}
.event-description .title {
  font-weight: bold;
  color: #fff;
  font-size: 3.5rem;
  line-height: 3.5rem;
  text-shadow: 4px 4px 4px #000;
}
.event-description .date {
  font-weight: bold;
  color: #fff;
  font-size: 1.75rem;
  line-height: 1.75rem;
  text-shadow: 4px 4px 4px #000;
  margin-top: 16px;
}
.event-description .link .btn {
  font-size: 1rem;
  border-width: 1px;
  color: #fff;
  border-color: #1a537e;
  /*box-shadow: 0px 1px 0px 2px #9fb4f2;
      text-shadow: 0px 1px 0px #283966;*/
  background-color: #20689e;
  filter: brightness(1);
  border-radius: 0;
  margin-top: 40px;
}
.event-description .link .btn:hover {
  background-color: #1a537e;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .event-description .title {
    font-size: 3rem;
    line-height: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .event-description {
    bottom: 10px;
  }
  .event-description .title {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }
  .event-description .date {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
}
@media (max-width: 767px) {
  .event-description {
    bottom: 10px;
  }
  .event-description .title {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
  .event-description .date {
    font-size: 1rem;
    line-height: 1rem;
    margin-top: 6px;
  }
  .event-description .link .btn {
    padding: 8px 16px;
  }
}
.striped .row:nth-child(even) {
  background-color: #eeeeee;
}
.striped .row:nth-child(odd) {
  background-color: #d0d0d0;
}
